<template>
    <div class="add_rise">
        <div class="rise_box">
            <div class="rise_header" >
              <img src="../assets/cloneBtn5.png" @click="riseHeader"/>
            </div>
            <!-- select all -->
            <div class="selectStatus1">
                <van-radio-group class="selectList" @change="selectBtn" v-model="radioValue" icon-size="18px" direction="horizontal">
                    <van-radio checked-color="#E5C187" name="1">个人<span></span></van-radio>
                    <van-radio checked-color="#E5C187" name="2">单位</van-radio>
                </van-radio-group>
            </div>
            <div>
                <div class="from_item">
                    <div>单位名称：</div>
                    <input v-model="organization" class="inputclass" />
                </div>
                <div class="from_item" v-if="radioValue==2">
                    <div>纳税人识别号：</div>
                    <input v-model="taxpayer" class="inputclass" />
                </div>
                <div class="from_item">
                    <div>邮箱：</div>
                    <input  v-model="mailbox" class="inputclass" />
                </div>
            </div>
            <div class="comfim" @click="confim">确认信息</div>
        </div>
    </div>
</template>
<script>
import {invoiceDetails_api} from "@/api/deal";
import { Toast } from "vant";
export default {
    data() {
        return {
            radioValue:'0',
            organization:'',
            taxpayer:'',
            mailbox:''
        }
    },
    props: {
      dataList:{
        type:Object
      }
    },
    methods:{
      selectBtn(){
        this.organization ="";
        this.mailbox ="";
        this.mailbox ="";
        if(this.radioValue==1){
          this.organization = this.dataList.pagination.invoice_header.personage.organization;
          this.mailbox = this.dataList.pagination.invoice_header.personage.mailbox;
        }else{
          this.organization = this.dataList.pagination.invoice_header.enterprise.organization;
          this.taxpayer = this.dataList.pagination.invoice_header.enterprise.taxpayer;
          this.mailbox = this.dataList.pagination.invoice_header.enterprise.mailbox;
        }
      },
        riseHeader(){
            this.$emit("riseHeader")
        },
        confim(){
          let data = {
            invoice_header:{
              personage:null,
              enterprise:null
            }
          }
          if(this.dataList.pagination.invoice_header){
              data.invoice_header = this.dataList.pagination.invoice_header
          }
          if(this.radioValue==1){
            data.invoice_header.personage = {
              mailbox:this.mailbox,
              organization:this.organization,
            }
            for(var i in data.invoice_header.personag){
              if(!data.invoice_header.personag[i]){
                this.$toast("请完善表单");
                return false 
              }
            }
          }else{
            data.invoice_header.enterprise = {
              mailbox:this.mailbox,
              taxpayer:this.taxpayer,
              organization:this.organization,
            }
            for(var j in data.invoice_header.enterprise){
              if(!data.invoice_header.enterprise[j]){
                this.$toast("请完善表单");
                return false 
              }
            }
          }
          invoiceDetails_api(data).then(res=>{
            if(res.code== 0){
              Toast({
                message: "操作成功",
                icon: "success",
                duration: 1000
              });
              this.$emit("invoiceBtn")
            }
          })
        }
    }
};
</script>
<style>
.add_rise {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.rise_box {
  width: 700px;
  height: 1024px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 100px auto 0;
}
.rise_header {
  width: 700px;
  height: 143px;
  background: #2a2828;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: relative;
}

.rise_header img{
  width:60px;height:60px;
  position: absolute;
  right:30px;top:30px
}
.selectStatus1 {
  margin-top: 60px;
  margin-left: 67px;
  margin-bottom: 50px;
}
.van-radio__label {
  font-size: 32px;
}
.van-radio {
  margin-right: 30px;
}
.selectList {
  margin-left: 20px;
}
.inputclass {
  width: 580px;
  height: 90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.5);
  background-color: transparent;
  margin-bottom: 30px;
}
.from_item {
  margin-left: 60px;
  font-size: 32px;
  color: #2a2828;
}
.from_item div {
  margin-bottom: 25px;
}

.from_item input{
  padding-left: 20px;box-sizing: border-box;
}
.comfim {
  width: 400px;
  height: 100px;
  background: #2d2929;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #e7c48b;
  font-size: 32px;
  color: #fff;
  text-align: center;
  line-height: 100px;
  margin: 20px auto 0;
}
</style>
