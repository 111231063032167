<template>
    <div class="content_rise">
        <div class="price_title">请确认发票抬头</div>
        

        <!-- select all -->
        <div v-if="dataList.pagination.invoice_header">
            <div class="selectStatus">
                <van-radio-group class="selectList" @change="changeBtn" v-model="radio" icon-size="18px" direction="horizontal">
                    <van-radio v-if="dataList.pagination.invoice_header.personage" checked-color="#E5C187" name="1">个人<span></span></van-radio>
                    <van-radio v-if="dataList.pagination.invoice_header.enterprise" checked-color="#E5C187" name="2">单位</van-radio>
                </van-radio-group>
            </div>

            <!-- 企业 -->
            <div class="personage" v-if="radio==2">
                <div>单位名称：{{dataList.pagination.invoice_header.enterprise.organization}}</div>
                <div>纳税人识别号：{{dataList.pagination.invoice_header.enterprise.taxpayer}}</div>
                <div>邮箱：{{dataList.pagination.invoice_header.enterprise.mailbox}}</div>
            </div>

            <!-- 个人 -->
            <div class="personage" v-else-if="radio==1">
                <div>姓名：{{dataList.pagination.invoice_header.personage.organization}}</div>
                <div>邮箱：{{dataList.pagination.invoice_header.personage.mailbox}}</div>
            </div>
        </div>
        <!-- select null -->
        <div class="addRise" @click="invoiceBtn" v-else>暂无发票抬头信息，添加抬头+</div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "",
      selectShow: false
    };
  },
  props: {
    dataList: {
      type: Object
    }
  },
  created() {},
  methods: {
    invoiceBtn() {
      this.$emit("invoiceBtn");
    },
    changeBtn(){
        this.$emit("changeBtn",this.radio);
    }
  }
};
</script>
<style>
.content_rise {
  margin: 50px 0 0 25px;
}
.addRise {
  width: 700px;
  height: 90px;
  background: #fff1d8;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.5);
  font-size: 26px;
  color: #2a2828;
  text-align: center;
  line-height: 90px;
  margin-top: 24px;
}
.selectStatus {
  margin-top: 30px;
}
.van-radio__label {
  font-size: 32px;
}
.van-radio {
  margin-right: 30px;
}
.selectList {
  margin-left: 20px;
}
.personage {
  font-size: 32px;
  color: #2a2828;
  margin-left: 50px;
  margin-top: 30px;
}
.personage div {
  margin-bottom: 20px;
}
</style>
