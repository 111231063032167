<template>
    <div class="container_invoice">

        <clone-tab :bgcShow="bgcShow"></clone-tab>

        <!-- 用戶信息 -->
        <div class="userInfo">
            <div class="user_header1">
                <div>可开票金额</div>
                <div class="invoice_btn"  @click="invoiceClone">发票抬头管理></div>
            </div>
            <div class="price_i">{{dataList.pagination.apply_amount}}元</div>
            <div class="history_i" @click="lookList">开票记录</div>
        </div>

        <!-- 开票金额 -->
        <div class="invoice_price">
            <div class="price_title">请输入开票金额</div>
            <div class="input_text">
                <input v-model="invoice_amount" :placeholder="text" />
                元
            </div>
        </div>

        <!-- 抬头 -->
        <rise @invoiceBtn="invoiceClone" @changeBtn="changeBtn" :dataList="dataList"></rise>

        <!-- 抬头性质 -->
        <div class="invoice_price">
            <div class="price_title">发票介质</div>
            <div class="img_text">
                <img src="../../assets/Slice39.png" />
                <div>电子发票（急速开票） </div>
                <img src="../../assets/Slice43.png" />
            </div>
        </div>

        <!-- 备注 -->
        <div class="invoice_price">
            <div class="price_title imgs">填写发票备注</div>
            <textarea class="textarea" v-model="textfrom"></textarea>
            <div class="price_in">待开票金额：<span>{{dataList.pagination.apply_amount}}元</span></div>
        </div>

        <!-- 提交 -->
        <div class="submitBtn_i" @click="submit">申请开票</div>

        <!-- 添加抬头 -->
        <add-rise v-if="riseShow" @invoiceBtn="invoiceBtn" :dataList="dataList" @riseHeader="riseHeader"></add-rise>
    </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import addRise from "../../components/addRise"
import rise from "../../components/rise";
import {invoiceAccess_api,invoicesubmit_api} from "@/api/deal"
export default {
  data() {
    return {
      bgcShow: true,
      riseShow: false,
      text:'',
      dataList:{
        pagination:{
        
        }
      },
      status:'',
      textfrom:'',
      invoice_amount:''
    };
  },
  components: {
    cloneTab,
    rise,
    addRise
  },
  created() {
    document.title = "发票管理";
    this.getList()
  }, 
  methods: {
    changeBtn(status){
      this.status = status
    },
    submit(){
      let data = {
        invoice_amount:this.invoice_amount,
        invoice_type:this.status,
        invoice_info:{
          message:this.textfrom,
        },
        invoice_header:this.status==1?this.dataList.pagination.invoice_header.personage:this.dataList.pagination.invoice_header.enterprise
      };
      if(!this.invoice_amount){
        this.$toast("请输入开票金额");
        return false
      }
      invoicesubmit_api(data).then(res=>{
        if(res.code==0){
          this.$router.push("./outAnInvoice")
        }else{
          this.$toast(res.error)
        }
      })
    },
    riseHeader(){
        this.riseShow = false
    }, 
    getList() {
      invoiceAccess_api().then(res => {
        console.log(res);
        if(res.code == 0){
          this.dataList = res;
          this.text = `请输入本次开票金额（不可大于${this.dataList.pagination.apply_amount}）`
        }
      })
    },
    lookList(){
      this.$router.push("./outAnInvoice")
    },
    invoiceBtn(){
      this.riseShow = false;
      this.getList()
    },
    invoiceClone(){
      this.riseShow = true
    }
  }
};
</script>
<style>
.container_invoice {
  padding-top: 40px;
}
.userInfo {
  width: 720px;
  height: 400px;
  background: linear-gradient(134deg, #e5c187 0%, #f5d398 100%);
  box-shadow: 0px 5px 15px 0px rgba(175, 106, 0, 0.2);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin: 40px auto 49px;
  padding: 46px 50px;
  box-sizing: border-box;
}
.user_header1 {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  color: #563b15;
}
.invoice_btn {
  font-size: 26px;
}
.price_i {
  font-size: 80px;
  color: #fff;
  margin: 49px 0 30px;
}
.history_i {
  width: 190px;
  height: 74px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  box-shadow: 0px 4px 10px 0px rgba(184, 152, 100, 0.5);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  text-align: center;
  font-size: 30px;
  line-height: 74px;
  color: #fff;
  margin-left: 400px;
}
.invoice_price {
  margin-left: 25px;
  margin-top: 20px
}
.price_title {
  font-size: 34px;
  color: #2a2828;
  font-weight: bold;
  margin-left: 15px;
}
.input_text input {
  width: 600px;
  height: 90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.5);
  background-color: transparent;
  margin-top: 24px;
  padding-left: 20px;
  box-sizing: border-box;
  padding-left:20px
}

input::-webkit-input-placeholder {
  /* WebKit browsers*/
  color: #d0d0d0;
  font-size: 26px;
}
input:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18*/
  color: #d0d0d0;
  font-size: 26px;
}
input::-moz-input-placeholder {
  /* Mozilla Firefox 19+*/
  color: #d0d0d0;
  font-size: 26px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+*/
  color: #d0d0d0;
  font-size: 26px;
}

.img_text {
  display: flex;
  font-size: 26px;
  margin: 30px 0 0 22px;
}
.img_text img {
  width: 30px;
  height: 30px;
}

.textarea {
  width: 700px;
  height: 300px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.5);
}

.imgs {
  margin: 48px 0 24px;
}

.price_in {
  font-size: 40px;
  color: #2a2828;
  font-weight: bold;
  text-align: right;
  margin-right: 30px;
  margin-bottom: 137px;
  margin-top: 40px
}
.price_in span {
  font-size: 35px;
}

.submitBtn_i {
  width: 400px;
  height: 100px;
  background: #2d2929;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #e7c48b;
  text-align:center;
  line-height:100px;
  font-size: 32px;color:#E7C58C;
  margin: 10px auto 30px
}

.van-toast {
  width: 300px;
  min-height: 120px;
}
</style>